'use strict';

(function() {
	let Locr = {
		createNS: function(namespace) {
			let nsparts = namespace.split('.');
			let parent = this;

			if (nsparts[0] === 'Locr') {
				nsparts = nsparts.slice(1);
			}

			for(let i = 0; i < nsparts.length; i++) {
				const partname = nsparts[i];
				if (typeof parent[partname] === 'undefined') {
					parent[partname] = {};
				}
				parent = parent[partname];
			}

			return parent;
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['color', 'convert', 'length', 'geo', 'geometry', 'math'];
		for(const requireItem of requires) {
			const required = require('./src/' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['error', 'math'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	const decartaColors = {
		BK: '#000000',
		WH: '#FFFFFF',
		GR: '#808080',
		SI: '#C0C0C0',
		MR: '#800000',
		RD: '#FF0000',
		GN: '#008000',
		LI: '#00FF00',
		OL: '#808000',
		YE: '#FFFF00',
		NA: '#000080',
		BL: '#0000FF',
		PU: '#800080',
		FU: '#FF00FF',
		TE: '#008080',
		AQ: '#00FFFF'
	};

	const knownColors = {
		aliceblue: '#F0F8FF',
		antiqueqhite: '#FAEBD7',
		aqua: '#00FFFF',
		aquamarine: '#7FFFD4',
		azure: '#F0FFFF',
		beige: '#F5F5DC',
		bisque: '#FFE4C4',
		black: '#000000',
		blanchedalmond: '#FFEBCD',
		blue: '#0000FF',
		blueviolet: '#8A2BE2',
		brown: '#A52A2A',
		burlywood: '#DEB887',
		cadetblue: '#5F9EA0',
		chartreuse: '#7FFF00',
		chocolate: '#D2691E',
		coral: '#FF7F50',
		cornflowerblue: '#6495ED',
		cornsilk: '#FFF8DC',
		crimson: '#DC143C',
		cyan: '#00FFFF',
		darkblue: '#00008B',
		darkcyan: '#008B8B',
		darkgoldenrod: '#B8860B',
		darkgray: '#A9A9A9',
		darkgrey: '#A9A9A9',
		darkgreen: '#006400',
		darkkhaki: '#BDB76B',
		darkmagenta: '#8B008B',
		darkolivegreen: '#556B2F',
		darkorange: '#FF8C00',
		darkorchid: '#9932CC',
		darkred: '#8B0000',
		darksalmon: '#E9967A',
		darkseagreen: '#8FBC8B',
		darkslateblue: '#483D8B',
		darkslategray: '#2F4F4F',
		darkslategrey: '#2F4F4F',
		darkturquoise: '#00CED1',
		darkviolet: '#9400D3',
		deeppink: '#FF1493',
		deepskyblue: '#00BFFF',
		dimgray: '#696969',
		dimgrey: '#696969',
		dodgerblue: '#1E90FF',
		firebrick: '#B22222',
		floralwhite: '#FFFAF0',
		forestgreen: '#228B22',
		fuchsia: '#FF00FF',
		gainsboro: '#DCDCDC',
		ghostwhite: '#F8F8FF',
		gold: '#FFD700',
		goldenrod: '#DAA520',
		gray: '#808080',
		grey: '#808080',
		green: '#008000',
		greenyellow: '#ADFF2F',
		honeydew: '#F0FFF0',
		hotpink: '#FF69B4',
		indianred: '#CD5C5C',
		indigo: '#4B0082',
		ivory: '#FFFFF0',
		khaki: '#F0E68C',
		lavender: '#E6E6FA',
		lavenderblush: '#FFF0F5',
		lawngreen: '#7CFC00',
		lemonchiffon: '#FFFACD',
		lightblue: '#ADD8E6',
		lightcoral: '#F08080',
		lightcyan: '#E0FFFF',
		lightgoldenrodyellow: '#FAFAD2',
		lightgray: '#D3D3D3',
		lightgrey: '#D3D3D3',
		lightgreen: '#90EE90',
		lightpink: '#FFB6C1',
		lightsalmon: '#FFA07A',
		lightseagreen: '#20B2AA',
		lightskyblue: '#87CEFA',
		lightslategray: '#778899',
		lightslategrey: '#778899',
		lightsteelblue: '#B0C4DE',
		lightyellow: '#FFFFE0',
		lime: '#00FF00',
		limegreen: '#32CD32',
		linen: '#FAF0E6',
		locr: '#FF7300',
		magenta: '#FF00FF',
		maroon: '#800000',
		mediumaquamarine: '#66CDAA',
		mediumblue: '#0000CD',
		mediumorchid: '#BA55D3',
		mediumpurple: '#9370DB',
		mediumseagreen: '#3CB371',
		mediumslateblue: '#7B68EE',
		mediumspringgreen: '#00FA9A',
		mediumturquoise: '#48D1CC',
		mediumvioletred: '#C71585',
		midnightblue: '#191970',
		mintcream: '#F5FFFA',
		mistyrose: '#FFE4E1',
		moccasin: '#FFE4B5',
		navajowhite: '#FFDEAD',
		navy: '#000080',
		oldlace: '#FDF5E6',
		olive: '#808000',
		olivedrab: '#6B8E23',
		orange: '#FFA500',
		orangered: '#FF4500',
		orchid: '#DA70D6',
		palegoldenrod: '#EEE8AA',
		palegreen: '#98FB98',
		paleturquoise: '#AFEEEE',
		palevioletred: '#DB7093',
		papayawhip: '#FFEFD5',
		peachpuff: '#FFDAB9',
		peru: '#CD853F',
		pink: '#FFC0CB',
		plum: '#DDA0DD',
		powderblue: '#B0E0E6',
		purple: '#800080',
		red: '#FF0000',
		rosybrown: '#BC8F8F',
		royalblue: '#4169E1',
		saddlebrown: '#8B4513',
		salmon: '#FA8072',
		sandybrown: '#F4A460',
		seagreen: '#2E8B57',
		seashell: '#FFF5EE',
		sienna: '#A0522D',
		silver: '#C0C0C0',
		skyblue: '#87CEEB',
		slateblue: '#6A5ACD',
		slategray: '#708090',
		slategrey: '#708090',
		snow: '#FFFAFA',
		springgreen: '#00FF7F',
		steelblue: '#4682B4',
		tan: '#D2B48C',
		teal: '#008080',
		thistle: '#D8BFD8',
		tomato: '#FF6347',
		transparent: '#FFFFFFFF',
		turquoise: '#40E0D0',
		violet: '#EE82EE',
		wheat: '#F5DEB3',
		white: '#FFFFFF',
		whitesmoke: '#F5F5F5',
		yellow: '#FFFF00',
		yellowgreen: '#9ACD32'
	};

	Locr.Color = class Color {
		constructor(param1, param2, param3, param4) {
			this._red = 0;
			this._green = 0;
			this._blue = 0;
			this._alpha = 255;
			this._originalValue = '';

			const param1Type = typeof param1;
			switch (param1Type) {
				case 'string':
					this.Parse(param1);
					break;

				case 'number':
					if (typeof param2 === 'number' && typeof param3 === 'number') {
						this.Red = param1;
						this.Green = param2;
						this.Blue = param3;
						if (typeof param4 === 'number') {
							this.Alpha = param4;
						}
					}
					break;

				case 'object':
					if (param1 instanceof Array) {
						if (!this.ParseJsonArray(JSON.stringify(param1))) {
							throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The array-value of the parameter "color" for new Locr.Color(color) is invalid.');
						}
						return;
					}
					throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The type of the parameter "color" for new Locr.Color(color) must of type "string", "number" or "array". Type "' + param1Type + '" was given.');

				case 'undefined': // ignore
					break;

				default:
					throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The type of the parameter "color" for new Locr.Color(color) must of type "string", "number" or "array". Type "' + param1Type + '" was given.');
			}
		}

		get OriginalValue() {
			return this._originalValue;
		}

		get Red() {
			return this._red;
		}

		set Red(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Red(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Red (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._red = value;
		}

		get Green() {
			return this._green;
		}

		set Green(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Green(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Green (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._green = value;
		}

		get Blue() {
			return this._blue;
		}

		set Blue(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Blue(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Blue (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._blue = value;
		}

		get Alpha() {
			return this._alpha;
		}

		set Alpha(value) {
			if (typeof value !== 'number') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'value', 1, 'The value for Color.Alpha(value) must of type "number".');
			}

			if (value < 0 || value > 255) {
				throw new Locr.ArgumentError(Locr.Error.OUT_OF_RANGE, 'value', 1, 'The argument for the value Color.Alpha (' + value + ') is out of range. It must be between 0 and 255.');
			}

			this._alpha = value;
		}

		Reset() {
			this._red = 0;
			this._green = 0;
			this._blue = 0;
			this._alpha = 255;
		}

		Colorize(color) {
			if (!(color instanceof Color)) {
				color = new Color(color);
			}

			return new Color(Math.round(this.Red * color.Red / 255.0), Math.round(this.Green * color.Green / 255.0), Math.round(this.Blue * color.Blue / 255.0), Math.round(this.Alpha * color.Alpha / 255.0));
		}

		Grayscale() {
			const grayValue = Math.floor(this._red * 0.299 + this._green * 0.587 + this._blue * 0.114);
			return new Color(grayValue, grayValue, grayValue);
		}

		Invert() {
			return new Color(255 - this.Red, 255 - this.Green, 255 - this.Blue, this.Alpha);
		}

		InvertLuminescence() {
			const hsl = this.GetHSLValue();
			const newColor = new Color();
			newColor.SetHSLValue({
				h: hsl.h,
				s: hsl.s,
				l: 1.0 - hsl.l
			});
			return newColor;
		}

		InterpolateHSV(color, interpolation) {
			const i = Math.max(Math.min(interpolation, 1.0), 0.0);

			const hsva = this.GetHSVA();
			const secondHSVA = color.GetHSVA();

			const newH = hsva.h + (secondHSVA.h - hsva.h) * i;
			const newS = hsva.s + (secondHSVA.s - hsva.s) * i;
			const newV = hsva.v + (secondHSVA.v - hsva.v) * i;
			const newA = this.Alpha + (color.Alpha - this.Alpha) * i / 255.0;

			const interpolatedColor = new Color();
			interpolatedColor.SetHSVA(newH, newS, newV, newA);
			return interpolatedColor;
		}

		GetRGBFromHSV(h, s, v) {
			if (h < 0.0 || h > 360.0) {
				h = ((h % 360.0) + 360.0) % 360.0;
			}
			if (s < 0.0) {
				s = 0.0;
			} else if (s > 1.0) {
				s = 1.0;
			}
			if (v < 0.0) {
				v = 0.0;
			} else if (v > 1.0) {
				v = 1.0;
			}

			const c = v * s;
			const x = c * (1.0 - Math.abs((h / 60.0) % 2.0 - 1.0));
			const m = v - c;

			let r1 = 0.0;
			let g1 = 0.0;
			let b1 = 0.0;
			if ((h >= 0.0 && h < 60.0) || h === 360.0) {
				r1 = c;
				g1 = x;
			} else if (h >= 60.0 && h < 120.0) {
				r1 = x;
				g1 = c;
			} else if (h >= 120.0 && h < 180.0) {
				g1 = c;
				b1 = x;
			} else if (h >= 180.0 && h < 240.0) {
				g1 = x;
				b1 = c;
			} else if (h >= 240.0 && h < 300.0) {
				r1 = x;
				b1 = c;
			} else if (h >= 300.0 && h < 360.0) {
				r1 = c;
				b1 = x;
			}

			return {
				r: Math.round((r1 + m) * 255.0),
				g: Math.round((g1 + m) * 255.0),
				b: Math.round((b1 + m) * 255.0)
			};
		}

		GetHSVA() {
			let min = 1.0;
			let max = 0.0;

			const red = this.Red / 255.0;
			const green = this.Green / 255.0;
			const blue = this.Blue / 255.0;
			const alpha = Locr.Math.Round(this.Alpha / 255.0, 2);

			if (red < min) {
				min = red;
			} // if (red < min)
			if (green < min) {
				min = green;
			} // if (green < min)
			if (blue < min) {
				min = blue;
			} // if (blue < min)
			if (red > max) {
				max = red;
			} // if (red > max)
			if (green > max) {
				max = green;
			} // if (green > max)
			if (blue > max) {
				max = blue;
			} // if (blue > max)

			if (max === 0.0) {
				return {
					h: 0.0,
					s: 0.0,
					v: 0.0,
					a: alpha
				};
			} // if (max === 0.0)

			const v = max;
			const delta = max - min;
			const s = delta / max;
			let h = 0.0;
			if (delta !== 0.0) {
				if (red === max) {
					h = (green - blue) / delta;
				} else if (green === max) {
					h = 2.0 + (blue - red) / delta;
				} else {
					h = 4.0 + (red - green) / delta;
				};

				h *= 60.0;
				if (h < 0.0) {
					h += 360.0;
				} // if (h < 0.0)
			} // if (delta != 0.0)

			return {
				h: h,
				s: s,
				v: v,
				a: alpha
			};
		}

		GetHSLValue() {
			const hsl = {
				h: 0,
				s: 0,
				l: 0
			};

			let min = 1.0;
			let max = 0.0;

			const red = this._red / 255.0;
			const green = this._green / 255.0;
			const blue = this._blue / 255.0;

			if (red < min) {
				min = red;
			}
			if (green < min) {
				min = green;
			}
			if (blue < min) {
				min = blue;
			}
			if (red > max) {
				max = red;
			}
			if (green > max) {
				max = green;
			}
			if (blue > max) {
				max = blue;
			}

			hsl.l = (max + min) / 2.0;

			if (max === min) {
				hsl.s = 0.0;
			} else {
				hsl.s = (hsl.l < 0.5) ? (max - min) / (max + min) : (max - min) / (2.0 - max - min);
			}

			if (red === max) {
				hsl.h = (green - blue) / (max - min);
			} else if (green === max) {
				hsl.h = 2.0 + (blue - red) / (max - min);
			} else if (blue === max) {
				hsl.h = 4.0 + (red - green) / (max - min);
			}

			hsl.h *= 60.0;
			if (hsl.h < 0) {
				hsl.h += 360.0;
			}

			return hsl;
		}

		SetHSVA(hue, saturation, value, alpha) {
			let a = 0;
			if (alpha < 0.0) {
				a = 0;
			} else if (alpha > 1.0) {
				a = 255;
			} else {
				a = Math.round(alpha * 255.0);
			};

			const rgb = this.GetRGBFromHSV(hue, saturation, value);

			this.Red = rgb.r;
			this.Green = rgb.g;
			this.Blue = rgb.b;
			this.Alpha = a;
		}

		SetHSLValue(hsl) {
			if (hsl.h < 0.0 || hsl.h > 360.0) {
				throw new Error('The argument for h[hue] (' + hsl.h + ') is out of range. It must be between 0.0 and 360.0.');
			}
			if (hsl.s < 0.0 || hsl.s > 1.0) {
				throw new Error('The argument for s[saturation] (' + hsl.s + ') is out of range. It must be between 0.0 and 1.0.');
			}
			if (hsl.l < 0.0 || hsl.l > 1.0) {
				throw new Error('The argument for l[lightness] (' + hsl.l + ') is out of range. It must be between 0.0 and 1.0.');
			}

			this.Reset();

			if (hsl.s === 0) {
				this.Red = parseInt(Math.round(hsl.l * 255.0));
				this.Green = parseInt(Math.round(hsl.l * 255.0));
				this.Blue = parseInt(Math.round(hsl.l * 255.0));
			} else {
				const t3 = [0, 0, 0];
				const c = [0, 0, 0];
				const t2 = (hsl.l < 0.5) ? hsl.l * (1.0 + hsl.s) : hsl.l + hsl.s - hsl.l * hsl.s;
				const t1 = 2.0 * hsl.l - t2;
				hsl.h /= 360.0;
				t3[0] = hsl.h + 1.0 / 3.0;
				t3[1] = hsl.h;
				t3[2] = hsl.h - 1.0 / 3.0;
				for(let i = 0; i < 3; i++) {
					if (t3[i] < 0.0) {
						t3[i] += 1.0;
					}
					if (t3[i] > 1.0) {
						t3[i] -= 1.0;
					}
					if (6.0 * t3[i] < 1.0) {
						c[i] = t1 + (t2 - t1) * 6.0 * t3[i];
					} else if (2.0 * t3[i] < 1.0) {
						c[i] = t2;
					} else if (3.0 * t3[i] < 2.0) {
						c[i] = t1 + (t2 - t1) * ((2.0 / 3.0) - t3[i]) * 6.0;
					} else {
						c[i] = t1;
					}
				}

				this.Red = parseInt(Math.round(c[0] * 255.0));
				this.Green = parseInt(Math.round(c[1] * 255.0));
				this.Blue = parseInt(Math.round(c[2] * 255.0));
			}
		}

		Parse(color) {
			if (typeof color !== 'string') {
				throw new Locr.ArgumentError(Locr.Error.INVALID_TYPE, 'color', 1, 'The value for Color.Parse(color) must of type "string".');
			}

			this.Reset();

			if (this.ParseDecarta(color.toUpperCase())) {
				this._originalValue = color;
				return;
			}
			if (this.ParseKnown(color.toLowerCase())) {
				this._originalValue = color;
				return;
			}
			if (this.ParseHex(color)) {
				this._originalValue = color;
				return;
			}
			if (this.ParseRgb(color)) {
				this._originalValue = color;
				return;
			}
			if (this.ParseRgba(color)) {
				this._originalValue = color;
				return;
			}
			if (this.ParseCmyk(color)) {
				this._originalValue = color;
				return;
			}
			if (this.ParseJsonArray(color)) {
				this._originalValue = color;
				return;
			}

			throw new Locr.ArgumentError(Locr.Error.INVALID_VALUE, 'color', 1, 'The given string "' + color + '" could not been parsed to a color.');
		}

		ParseDecarta(color) {
			if (typeof decartaColors[color] !== 'undefined') {
				return this.ParseHex(decartaColors[color]);
			}

			return false;
		}

		ParseKnown(color) {
			if (typeof knownColors[color] !== 'undefined') {
				return this.ParseHex(knownColors[color]);
			}

			return false;
		}

		ParseHex(color) {
			const hexColorRegex = /^\s*#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})?\s*$/;
			const hexColorMatch = color.match(hexColorRegex);
			if (!hexColorMatch) {
				return false;
			}

			this.Red = parseInt(hexColorMatch[1], 16);
			this.Green = parseInt(hexColorMatch[2], 16);
			this.Blue = parseInt(hexColorMatch[3], 16);
			if (hexColorMatch[4]) {
				this.Alpha = parseInt(hexColorMatch[4], 16);
			}

			return true;
		}

		ParseRgb(color) {
			const rgbColorRegex = /^\s*rgb\s*\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)\s*$/;
			const rgbColorMatch = color.match(rgbColorRegex);
			if (!rgbColorMatch) {
				return false;
			}

			this.Red = parseInt(rgbColorMatch[1]);
			this.Green = parseInt(rgbColorMatch[2]);
			this.Blue = parseInt(rgbColorMatch[3]);

			return true;
		}

		ParseRgba(color) {
			const rgbaColorRegex = /^\s*rgba\s*\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]+(\.[0-9]+)?)\s*\)\s*$/;
			const rgbaColorMatch = color.match(rgbaColorRegex);
			if (!rgbaColorMatch) {
				return false;
			}

			this.Red = parseInt(rgbaColorMatch[1]);
			this.Green = parseInt(rgbaColorMatch[2]);
			this.Blue = parseInt(rgbaColorMatch[3]);
			if (rgbaColorMatch[4].indexOf('.') !== -1) {
				this.Alpha = Math.round(parseFloat(rgbaColorMatch[4]) * 255);
			} else {
				this.Alpha = parseInt(rgbaColorMatch[4]);
			}

			return true;
		}

		ParseCmyk(color) {
			const cmykColorRegex = /^\s*cmyk\s*\(\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*,\s*([0-9]+(\.[0-9]+)?)\s*%?\s*\)\s*$/;
			const cmykColorMatch = color.match(cmykColorRegex);
			if (!cmykColorMatch) {
				return false;
			}

			const cyan = parseFloat(cmykColorMatch[1]);
			const magenta = parseFloat(cmykColorMatch[3]);
			const yellow = parseFloat(cmykColorMatch[5]);
			const black = parseFloat(cmykColorMatch[7]);

			this.Red = Math.round(255 * (1 - cyan / 100) * (1 - black / 100));
			this.Green = Math.round(255 * (1 - magenta / 100) * (1 - black / 100));
			this.Blue = Math.round(255 * (1 - yellow / 100) * (1 - black / 100));

			return true;
		}

		ParseJsonArray(color) {
			try {
				const json = JSON.parse(color);
				if (!(json instanceof Array)) {
					return false;
				}
				if (json.length < 3 || json.length > 4) {
					return false;
				}

				this.Red = json[0];
				this.Green = json[1];
				this.Blue = json[2];
				if (json.length === 4) {
					this.Alpha = json[3];
				}

				return true;
			} catch (exc) {
				return false;
			}
		}

		ToHEXString() {
			const padding = 2;
			let redString = this._red.toString(16);
			let greenString = this._green.toString(16);
			let blueString = this._blue.toString(16);
			while(redString.length < padding) {
				redString = '0' + redString;
			}
			while(greenString.length < padding) {
				greenString = '0' + greenString;
			}
			while(blueString.length < padding) {
				blueString = '0' + blueString;
			}

			let hexString = '#' + redString + greenString + blueString;
			if (this._alpha < 255) {
				let alphaString = this._alpha.toString(16);
				while(alphaString.length < padding) {
					alphaString = '0' + alphaString;
				}
				hexString += alphaString;
			}
			return hexString.toUpperCase();
		}

		ToRGBString() {
			return 'rgb(' + this.Red + ', ' + this.Green + ', ' + this.Blue + ')';
		}

		ToRGBAString() {
			return 'rgba(' + this.Red + ', ' + this.Green + ', ' + this.Blue + ', ' + Locr.Math.Round(this.Alpha / 255.0, 2) + ')';
		}

		ToCMYKString() {
			const r = this.Red / 255;
			const g = this.Green / 255;
			const b = this.Blue / 255;
			const black = 1 - Math.max(r, g, b);

			const cyan = Math.round((1 - r - black) / (1 - black) * 100);
			const magenta = Math.round((1 - g - black) / (1 - black) * 100);
			const yellow = Math.round((1 - b - black) / (1 - black) * 100);

			return 'cmyk(' + cyan + '%, ' + magenta + '%, ' + yellow + '%, ' + black + '%)';
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof window !== 'undefined' && typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Object.defineProperty(Locr, 'PRINT_DPI', {
		value: 300,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr, 'DISPLAY_DPI', {
		value: 96,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr, 'CENTIMER_PER_INCH', {
		value: 2.54,
		writable: false,
		enumerable: true,
		configurable: false
	});

	Locr.Convert = class Convert {
		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static CentimeterToPixel(value, dpi) {
			dpi = (dpi) || Locr.DISPLAY_DPI;
			return Math.round(value * dpi / Locr.CENTIMER_PER_INCH);
		}

		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static CmToPx(value, dpi) {
			return this.CentimeterToPixel(value, dpi);
		}

		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static PixelToCentimeter(value, dpi) {
			dpi = (dpi) || Locr.DISPLAY_DPI;
			return value / dpi * Locr.CENTIMER_PER_INCH;
		}

		/**
		 * @param {number} value
		 * @param {number} dpi [optional]
		 */
		static PxToCm(value, dpi) {
			return this.PixelToCentimeter(value, dpi);
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = [];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Error = class LocrError extends Error {
		constructor(code = Locr.Error.UNKNOWN, ...params) {
			super(...params);

			if (Error.captureStackTrace) {
				Error.captureStackTrace(this, LocrError);
			}

			this.code = code;
		}
	};

	Locr.ArgumentError = class ArgumentError extends Error {
		constructor(code = Locr.Error.UNKNOWN, name = '', position = 1, ...params) {
			super(...params);

			if (Error.captureStackTrace) {
				Error.captureStackTrace(this, ArgumentError);
			}

			this.code = code;
			this.name = name;
			this.position = position;
		}
	};

	Object.defineProperty(Locr.Error, 'UNKNOWN', {
		value: 1,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_ARGUMENT', {
		value: 2,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_TYPE', {
		value: 3,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'INVALID_VALUE', {
		value: 4,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Error, 'OUT_OF_RANGE', {
		value: 5,
		writable: false,
		enumerable: true,
		configurable: false
	});

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../locr'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.createNS('Locr.Geo');

	Object.defineProperty(Locr.Geo, 'EQUATORIAL_RADIUS', {
		value: 6378137,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'METER_PER_DEGREE_LATITUDE', {
		value: 1852.2,
		writable: false,
		enumerable: true,
		configurable: false
	});

	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DECIMAL', {
		value: 'D.XXYYZZ',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS', {
		value: 'signed_DMS',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DIRECTION', {
		value: 'DMS_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS_DECIMAL', {
		value: 'signed_DMS.S',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DECIMAL_DIRECTION', {
		value: 'DMS.S_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_DECIMAL', {
		value: 'signed_DM.M',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geo, 'COORDINATE_FORMAT_DEGREES_MINUTES_DECIMAL_DIRECTION', {
		value: 'DM.M_direction',
		writable: false,
		enumerable: true,
		configurable: false
	});

	Locr.Geo.CalculateNormalizedBoundings = function(boundings, rectangle) {
		const newBoundings = {
			LatitudeMin: boundings.LatitudeMin,
			LatitudeMax: boundings.LatitudeMax,
			LongitudeMin: boundings.LongitudeMin,
			LongitudeMax: boundings.LongitudeMax
		};
		const ldDegreesWidth = newBoundings.LongitudeMax - newBoundings.LongitudeMin;

		const ldNormalizedWidth = ldDegreesWidth / 360.0;
		const ldNormalizedHeight = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin) - Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax);
		let ldNormalizedRatio = (ldNormalizedHeight !== 0) ? ldNormalizedWidth / ldNormalizedHeight : 1;
		const ldMapRatio = rectangle.WidthPx / rectangle.HeightPx;

		if (ldNormalizedRatio === 0) {
			ldNormalizedRatio = 1;
		}
		if (ldMapRatio >= ldNormalizedRatio) {
			newBoundings.LongitudeMin -= (ldDegreesWidth * ldMapRatio / ldNormalizedRatio - ldDegreesWidth) / 2;
			newBoundings.LongitudeMax += (ldDegreesWidth * ldMapRatio / ldNormalizedRatio - ldDegreesWidth) / 2;
		} else {
			let ldNormalizedMin = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin);
			let ldNormalizedMax = Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax);

			ldNormalizedMin += (ldNormalizedWidth / ldMapRatio - ldNormalizedHeight) / 2;
			ldNormalizedMax -= (ldNormalizedWidth / ldMapRatio - ldNormalizedHeight) / 2;

			newBoundings.LatitudeMin = Locr.Geo.Normalized2Latitude(ldNormalizedMin);
			newBoundings.LatitudeMax = Locr.Geo.Normalized2Latitude(ldNormalizedMax);
		}

		if ((newBoundings.LongitudeMax - newBoundings.LongitudeMin) !== 0) {
			newBoundings.LatitudeMin = Locr.Geo.Normalized2Latitude(Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMin));
			newBoundings.LatitudeMax = Locr.Geo.Normalized2Latitude(Locr.Geo.Latitude2Normalized(newBoundings.LatitudeMax));
			newBoundings.LongitudeMin = Locr.Geo.Normalized2Longitude(Locr.Geo.Longitude2Normalized(newBoundings.LongitudeMin));
			newBoundings.LongitudeMax = Locr.Geo.Normalized2Longitude(Locr.Geo.Longitude2Normalized(newBoundings.LongitudeMax));
		}

		return new Locr.Geo.Boundings(newBoundings.LatitudeMin, newBoundings.LatitudeMax, newBoundings.LongitudeMin, newBoundings.LongitudeMax);
	};

	/**
	 * @param {Locr.Geo.Position} position
	 * @param {Locr.Geo.Boundings} boundings
	 * @param {Locr.Rectangle} rectangle
	 * @param {object]} opts { round: false }
	 * @returns {object} { x: <number>, y: <number> }
	 */
	Locr.Geo.CalculateXYByPosition = function(position, boundings, rectangle, opts) {
		if (boundings.LatitudeMax === boundings.LatitudeMin) {
			return {
				x: 0,
				y: 0
			};
		}

		const normalizedBoundings = Locr.Geo.CalculateNormalizedBoundings(boundings, rectangle);
		const totalPixels = rectangle.WidthPx * 360 / (normalizedBoundings.LongitudeMax - normalizedBoundings.LongitudeMin);
		const xLongMin = Locr.Geo.Longitude2Normalized(normalizedBoundings.LongitudeMin) * totalPixels;
		const yLatMax = Locr.Geo.Latitude2Normalized(normalizedBoundings.LatitudeMax) * totalPixels;
		const xLong = Locr.Geo.Longitude2Normalized(position.Longitude) * totalPixels;
		const yLat = Locr.Geo.Latitude2Normalized(position.Latitude) * totalPixels;

		const xy = {
			x: xLong - xLongMin,
			y: yLat - yLatMax
		};
		if (opts && opts.round) {
			xy.x = Math.round(xy.x);
			xy.y = Math.round(xy.y);
		}

		return xy;
	};

	/**
	 * @param {object} xy { x: <number>, y: <number> }
	 * @param {Locr.Geo.Boundings} boundings
	 * @param {Locr.Rectangle} rectangle
	 * @returns {Locr.Geo.Position}
	 */
	Locr.Geo.CalculatePositionByXY = function(xy, boundings, rectangle) {
		if (boundings.LongitudeMax === boundings.LongitudeMin) {
			return new Locr.Geo.Position(boundings.LatitudeMax, boundings.LongitudeMin);
		}

		const normalizedBoundings = Locr.Geo.CalculateNormalizedBoundings(boundings, rectangle);
		const totalPixels = rectangle.WidthPx * 360 / (normalizedBoundings.LongitudeMax - normalizedBoundings.LongitudeMin);
		const xTotal = Locr.Geo.Longitude2Normalized(normalizedBoundings.LongitudeMin) * totalPixels + xy.x;
		const yTotal = Locr.Geo.Latitude2Normalized(normalizedBoundings.LatitudeMax) * totalPixels + xy.y;
		const longitude = Locr.Geo.Normalized2Longitude(xTotal / totalPixels);
		const latitude = Locr.Geo.Normalized2Latitude(yTotal / totalPixels);

		return new Locr.Geo.Position(latitude, longitude);
	};

	Locr.Geo.CalculateTileByZoomAndPosition = function(zoom, position) {
		return {
			x: Math.floor(((position.Longitude + 180) / 360) * Math.pow(2, zoom)),
			y: Math.floor((1 - Math.log(Math.tan(Locr.Math.Deg2Rad(position.Latitude)) + 1 / Math.cos(Locr.Math.Deg2Rad(position.Latitude))) / Math.PI) / 2 * Math.pow(2, zoom))
		};
	};

	/**
	 * @source https://gis.stackexchange.com/questions/19632/how-to-calculate-the-optimal-zoom-level-to-display-two-or-more-points-on-a-map
	 * @param {Locr.Geo.Boundings} boundings
	 * @param {Locr.Rectange} rectangle
	 * @param {Object} opts [optional]
	 * @return number
	 */
	Locr.Geo.CalculateZoomByBoundingsAndRectangle = function(boundings, rectangle, opts) {
		let zoom = 0;

		const maxZoom = (opts && typeof opts['max-zoom'] === 'number') ? opts['max-zoom'] : 21;
		const tileSize = (opts && typeof opts['tile-size'] === 'number') ? opts['tile-size'] : 256;
		const tileRectangle = new Locr.Rectangle(tileSize + 'px', tileSize + 'px');

		for(zoom = 0; zoom <= maxZoom; zoom++) {
			const tileNorthWest = Locr.Geo.CalculateTileByZoomAndPosition(zoom, boundings.NorthWest);
			const tileSouthEast = Locr.Geo.CalculateTileByZoomAndPosition(zoom, boundings.SouthEast);
			const xTiles = tileSouthEast.x - tileNorthWest.x + 1;
			const yTiles = tileSouthEast.y - tileNorthWest.y + 1;
			const xTilesWidth = xTiles * tileSize;
			const yTilesWidth = yTiles * tileSize;
			if (xTilesWidth < rectangle.WidthPx || yTilesWidth < rectangle.HeightPx) {
				continue;
			}

			const northWestLatitudeMax = Locr.Geo.Tile2Latitude(tileNorthWest.y, zoom);
			const northWestLatitudeMin = Locr.Geo.Tile2Latitude(tileNorthWest.y + 1, zoom);
			const northWestLongitudeMin = Locr.Geo.Tile2Longitude(tileNorthWest.x, zoom);
			const northWestLongitudeMax = Locr.Geo.Tile2Longitude(tileNorthWest.x + 1, zoom);
			const northWestTileBoundings = new Locr.Geo.Boundings(northWestLatitudeMin, northWestLatitudeMax, northWestLongitudeMin, northWestLongitudeMax);
			const xyNorthWestTile = Locr.Geo.CalculateXYByPosition(boundings.NorthWest, northWestTileBoundings, tileRectangle);

			const southEastLatitudeMax = Locr.Geo.Tile2Latitude(tileSouthEast.y, zoom);
			const southEastLatitudeMin = Locr.Geo.Tile2Latitude(tileSouthEast.y + 1, zoom);
			const southEastLongitudeMin = Locr.Geo.Tile2Longitude(tileSouthEast.x, zoom);
			const southEastLongitudeMax = Locr.Geo.Tile2Longitude(tileSouthEast.x + 1, zoom);
			const southEastTileBoundings = new Locr.Geo.Boundings(southEastLatitudeMin, southEastLatitudeMax, southEastLongitudeMin, southEastLongitudeMax);
			const xySouthEastTile = Locr.Geo.CalculateXYByPosition(boundings.SouthEast, southEastTileBoundings, tileRectangle);

			const realSize = {
				width: Math.round(xTilesWidth - xyNorthWestTile.x - tileSize + xySouthEastTile.x),
				height: Math.round(yTilesWidth - xyNorthWestTile.y - tileSize + xySouthEastTile.y)
			};
			const expectedSize = {
				width: rectangle.WidthPx,
				height: rectangle.HeightPx
			};
			const diffSize = {
				width: expectedSize.width - realSize.width,
				height: expectedSize.height - realSize.height
			};

			if (diffSize.width > 4 || diffSize.height > 4) {
				return zoom + 1;
			}

			break;
		}

		return zoom;
	};

	Locr.Geo.Latitude2Normalized = function(latitude) {
		return Math.log(Math.tan((90.0 - (latitude)) * Math.PI / 360.0)) / (2.0 * Math.PI) + 0.5;
	};

	Locr.Geo.Longitude2Normalized = function(longitude) {
		return (longitude + 180.0) / 360.0;
	};

	Locr.Geo.Normalized2Latitude = function(y) {
		return 90.0 - Math.atan(Math.exp((y - 0.5) * 2.0 * Math.PI)) * 360.0 / Math.PI;
	};

	Locr.Geo.Normalized2Longitude = function(x) {
		return x * 360 - 180;
	};

	Locr.Geo.Tile2Longitude = function(x, z) {
		return x * 360 / Math.pow(2, z) - 180;
	};

	Locr.Geo.Tile2Latitude = function(y, z) {
		return 90 - Math.atan(Math.exp((y / Math.pow(2, z) - 0.5) * 2 * Math.PI)) * 360 / Math.PI;
	};

	Locr.Geo.Longitude2Tile = function(longitude, z) {
		return (longitude + 180) * Math.pow(2, z) / 360;
	};

	Locr.Geo.Latitude2Tile = function(latitude, z) {
		return (Math.log(Math.tan((90 - (latitude)) * Math.PI / 360)) / (2 * Math.PI) + 0.5) * Math.pow(2, z);
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;

		const requires = ['rectangle', 'geo/boundings', 'geo/g_polyline', 'geo/position'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../locr'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.createNS('Locr.Geometry');

	Object.defineProperty(Locr.Geometry, 'RAD2DEG', {
		value: 180.0 / Math.PI,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Geometry, 'DEG2RAD', {
		value: Math.PI / 180.0,
		writable: false,
		enumerable: true,
		configurable: false
	});

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;

		const requires = ['geometry/vector', 'geometry/vector_ld'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['math'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	const CONVERT_FACTORS = {
		FROM: {
			mm: {
				TO: {
					mm: 1,
					cm: 0.1,
					in: 0.0393701,
					dm: 0.01,
					ft: 0.00328084,
					m: 0.001,
					km: 0.000001,
					mi: 6.2137e-7
				}
			},
			cm: {
				TO: {
					mm: 10,
					cm: 1,
					in: 0.3937007874015748,
					dm: 0.1,
					ft: 0.0328084,
					m: 0.01,
					km: 0.00001,
					mi: 6.2137e-6
				}
			},
			in: {
				TO: {
					mm: 25.4,
					cm: 2.54,
					in: 1,
					dm: 0.254,
					ft: 0.08333333333333333,
					m: 0.0254,
					km: 2.54e-5,
					mi: 1.5783e-5
				}
			},
			dm: {
				TO: {
					mm: 100,
					cm: 10,
					in: 3.93701,
					dm: 1,
					ft: 0.328084,
					m: 0.1,
					km: 0.0001,
					mi: 6.213712121212e-5
				}
			},
			m: {
				TO: {
					mm: 1000,
					cm: 100,
					in: 39.3701,
					dm: 10,
					ft: 3.28084,
					m: 1,
					km: 0.001,
					mi: 0.000621371
				}
			},
			km: {
				TO: {
					mm: 1000000,
					cm: 100000,
					in: 39370.1,
					dm: 10000,
					ft: 3280.84,
					m: 1000,
					km: 1,
					mi: 0.621371
				}
			}
		}
	};

	Locr.Length = class Length {
		/**
		 * @param {string|number} length
		 * @param {string|number} unitOrDpi
		 * @param {number|string} dpi
		 */
		constructor(length, unitOrDpi, dpi) {
			this._value = 0.0;
			this._unit = 'm';
			this._dpi = Locr.Length.PIXEL_PER_INCH;

			unitOrDpi = unitOrDpi || '';

			if (typeof length !== 'undefined') {
				if (length instanceof Length) {
					this._value = length.Value;
					this._unit = length.Unit;
					this._dpi = length.DPI;
				} else {
					this.Parse(length, unitOrDpi, dpi);
				}
			}
		}

		/**
		 * @return {number}
		 */
		get DPI() {
			return this._dpi;
		}

		/**
		 * @param {number|string} dpi
		 */
		set DPI(dpi) {
			if (typeof dpi === 'string') {
				dpi = parseInt(dpi);
				if (isNaN(dpi)) {
					throw new Error('The string-value of dpi cannot been parsed as int.');
				}
			}
			if (typeof dpi !== 'number') {
				throw new Error('The type of dpi must be "number".');
			}
			if (dpi <= 0) {
				throw new Error('The value of dpi must be greater than 0.');
			}

			this._dpi = dpi;
		}

		/**
		 * @return {string}
		 */
		get Unit() {
			return this._unit;
		}

		/**
		 * @param {string} unit
		 */
		set Unit(unit) {
			this._unit = this.ParseUnit(unit);
		}

		/**
		 * @return {number}
		 */
		get Value() {
			return this._value;
		}

		/**
		 * @param {string|number} value
		 */
		set Value(value) {
			const valueType = typeof value;
			if (valueType === 'number') {
				this._value = value;
				return;
			} else if (valueType === 'string') {
				const parsedValue = parseFloat(value);
				if (!isNaN(parsedValue)) {
					this._value = parsedValue;
					return;
				}
			}

			this.Parse(value);
		}

		/**
		 * @param {string|number} length
		 * @param {string} unitOrDpi
		 * @param {number|string} dpi
		 */
		Parse(length, unitOrDpi, dpi) {
			unitOrDpi = unitOrDpi || '';
			let unitOrDpiType = typeof unitOrDpi;
			const dpiType = typeof dpi;
			let parsedValue = length;
			let lengthUnit = null;

			if (dpiType === 'undefined') {
				if (unitOrDpiType === 'number') {
					dpi = unitOrDpi;
					unitOrDpi = '';
					unitOrDpiType = 'string';
				} else if (unitOrDpiType === 'string') {
					const unitOrDpiIntParsed = parseInt(unitOrDpi);
					if (!isNaN(unitOrDpiIntParsed)) {
						dpi = unitOrDpiIntParsed;
						unitOrDpi = '';
						unitOrDpiType = 'string';
					}
				}
			} else if (dpiType === 'string') {
				dpi = parseInt(dpi);
				if (isNaN(dpi)) {
					throw new Error('The string-value of dpi cannot been parsed as int.');
				}
			}
			if (unitOrDpiType === 'string' && unitOrDpi !== '') {
				lengthUnit = this.ParseUnit(unitOrDpi);
			}

			if (typeof length === 'string') {
				length = length.toLowerCase();

				const lengthMatch = length.match(/^\s*(-?[0-9]+(\.[0-9]+)?)\s*(.+)?\s*$/);
				if (!lengthMatch) {
					throw new Error('Could not parse length.');
				}

				parsedValue = parseFloat(lengthMatch[1]);

				if (typeof lengthMatch[3] === 'string' && lengthMatch[3] !== '' && typeof unitOrDpi !== 'undefined' && unitOrDpi !== '') {
					throw new Error('To provide an explicit unit and a unit in the value is ambiguous.');
				}

				if (typeof lengthMatch[3] !== 'undefined' && unitOrDpi === '') {
					lengthUnit = this.ParseUnit(lengthMatch[3]);
				}
			}

			if (!lengthUnit) {
				throw new Error('A length-unit is required for Locr.Length.');
			}

			this._value = parsedValue;
			this._unit = lengthUnit;
			if (typeof dpi === 'number') {
				this._dpi = dpi;
			}
		}

		/**
		 * @param {string} unit
		 */
		ParseUnit(unit) {
			unit = unit.toLowerCase();

			if (unit === '') {
				return Locr.Length.METER;
			}

			const unitMatch = unit.match(/^\s*(miles?|mi|meters?|m|kilometers?|km|decimeters?|dm|centimeters?|cm|millimeters?|mm|feets?|ft|points?|pt|pixels?|px|inches|inch|in)\s*$/);
			if (!unitMatch) {
				throw new Error('Could not parse unit: ' + unit);
			}

			if (unitMatch[1] === '' || unitMatch[1] === 'm' || unitMatch[1] === 'meter' || unitMatch[1] === 'meters') {
				return Locr.Length.METER;
			} else if (unitMatch[1] === 'km' || unitMatch[1] === 'kilometer' || unitMatch[1] === 'kilometers') {
				return Locr.Length.KILOMETER;
			} else if (unitMatch[1] === 'dm' || unitMatch[1] === 'decimeter' || unitMatch[1] === 'decimeters') {
				return Locr.Length.DECIMETER;
			} else if (unitMatch[1] === 'cm' || unitMatch[1] === 'centimeter' || unitMatch[1] === 'centimeters') {
				return Locr.Length.CENTIMETER;
			} else if (unitMatch[1] === 'mm' || unitMatch[1] === 'millimeter' || unitMatch[1] === 'millimeters') {
				return Locr.Length.MILLIMETER;
			} else if (unitMatch[1] === 'ft' || unitMatch[1] === 'feet' || unitMatch[1] === 'feets') {
				return Locr.Length.FEET;
			} else if (unitMatch[1] === 'mi' || unitMatch[1] === 'mile' || unitMatch[1] === 'miles') {
				return Locr.Length.MILE;
			} else if (unitMatch[1] === 'in' || unitMatch[1] === 'inch' || unitMatch[1] === 'inches') {
				return Locr.Length.INCH;
			} else if (unitMatch[1] === 'pt' || unitMatch[1] === 'point' || unitMatch[1] === 'points') {
				return Locr.Length.POINT;
			} else if (unitMatch[1] === 'px' || unitMatch[1] === 'pixel' || unitMatch[1] === 'pixels') {
				return Locr.Length.PIXEL;
			}

			throw new Error('Could not parse unit: ' + unit);
		}

		Add(length, unit) {
			const addValue = new Length(length, unit);
			return new Length(this._value + addValue.To(this._unit).Value, this._unit);
		}

		AddValue(value) {
			return new Length(this._value + value, this._unit);
		}

		Subtract(length, unit) {
			const subtractValue = new Length(length, unit);
			return new Length(this._value - subtractValue.To(this._unit).Value, this._unit);
		}

		SubtractValue(value) {
			return new Length(this._value - value, this._unit);
		}

		Multiply(length, unit) {
			const multiplyValue = new Length(length, unit);
			return new Length(this._value * multiplyValue.To(this._unit).Value, this._unit);
		}

		MultiplyBy(factor) {
			return new Length(this._value * factor, this._unit);
		}

		Divide(length, unit) {
			const divideValue = new Length(length, unit);
			return new Length(this._value / divideValue.To(this._unit).Value, this._unit);
		}

		DivideBy(factor) {
			return new Length(this._value / factor, this._unit);
		}

		/**
		 * @param {string} destinationUnit
		 * @return {Locr.Length}
		 */
		To(destinationUnit) {
			let currentValue = this._value;
			let currentUnit = this._unit;
			if (destinationUnit === currentUnit) {
				return new Locr.Length(currentValue, currentUnit);
			}

			if (currentUnit === Locr.Length.POINT) {
				currentValue /= Locr.Length.POINT_PER_INCH;
				currentUnit = Locr.Length.INCH;
			} else if (currentUnit === Locr.Length.PIXEL) {
				currentValue /= this._dpi;
				currentUnit = Locr.Length.INCH;
			}

			if (destinationUnit === Locr.Length.POINT || destinationUnit === Locr.Length.PIXEL) {
				if (currentUnit !== Locr.Length.INCH) {
					currentValue = new Locr.Length(currentValue, currentUnit).To(Locr.Length.INCH).Value;
					currentUnit = Locr.Length.INCH;
				}
				if (destinationUnit === Locr.Length.POINT) {
					return new Locr.Length(currentValue * Locr.Length.POINT_PER_INCH, Locr.Length.POINT);
				} else if (destinationUnit === Locr.Length.PIXEL) {
					return new Locr.Length(currentValue * this._dpi, Locr.Length.PIXEL);
				}
			}

			return new Locr.Length(currentValue * CONVERT_FACTORS.FROM[currentUnit].TO[destinationUnit], destinationUnit);
		}

		ToMM() {
			return this.To(Locr.Length.MILLIMETER);
		}

		ToMillimeter() {
			return this.To(Locr.Length.MILLIMETER);
		}

		ToCM() {
			return this.To(Locr.Length.CENTIMETER);
		}

		ToCentimeter() {
			return this.To(Locr.Length.CENTIMETER);
		}

		ToIN() {
			return this.To(Locr.Length.INCH);
		}

		ToInch() {
			return this.To(Locr.Length.INCH);
		}

		ToDM() {
			return this.To(Locr.Length.DECIMETER);
		}

		ToDecimeter() {
			return this.To(Locr.Length.DECIMETER);
		}

		ToFT() {
			return this.To(Locr.Length.FEET);
		}

		ToFeet() {
			return this.To(Locr.Length.FEET);
		}

		ToM() {
			return this.To(Locr.Length.METER);
		}

		ToMeter() {
			return this.To(Locr.Length.METER);
		}

		ToKM() {
			return this.To(Locr.Length.KILOMETER);
		}

		ToKilometer() {
			return this.To(Locr.Length.KILOMETER);
		}

		ToMI() {
			return this.To(Locr.Length.MILE);
		}

		ToMile() {
			return this.To(Locr.Length.MILE);
		}

		ToPX() {
			return this.To(Locr.Length.PIXEL);
		}

		ToPixel() {
			return this.To(Locr.Length.PIXEL);
		}

		ToPT() {
			return this.To(Locr.Length.POINT);
		}

		ToPoint() {
			return this.To(Locr.Length.POINT);
		}

		ToFormattedString() {
			return this._value + this._unit;
		}
	};

	Object.defineProperty(Locr.Length, 'PIXEL', {
		value: 'px',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'POINT', {
		value: 'pt',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'MILLIMETER', {
		value: 'mm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'CENTIMETER', {
		value: 'cm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'DECIMETER', {
		value: 'dm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'METER', {
		value: 'm',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'KILOMETER', {
		value: 'km',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'FEET', {
		value: 'ft',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'INCH', {
		value: 'in',
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'MILE', {
		value: 'mi',
		writable: false,
		enumerable: true,
		configurable: false
	});

	Object.defineProperty(Locr.Length, 'METER_PER_KILOMETER', {
		value: 1000,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'KILOMETER_PER_MILE', {
		value: 1.609344,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'FEET_PER_MILE', {
		value: 5280,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'CENTIMETER_PER_INCH', {
		value: 2.54,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'POINT_PER_INCH', {
		value: 72,
		writable: false,
		enumerable: true,
		configurable: false
	});
	Object.defineProperty(Locr.Length, 'PIXEL_PER_INCH', {
		value: 96,
		writable: false,
		enumerable: true,
		configurable: false
	});

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof window !== 'undefined' && typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Math = class LocrMath {
		/**
		 * @param {number} number
		 */
		static Deg2Rad(number) {
			return number * (Math.PI / 180);
		}

		/**
		 * @param {number} number
		 */
		static Rad2Deg(number) {
			return number * (180 / Math.PI);
		}

		/**
		 * @param {number} number
		 * @param {number} precision
		 * @returns {number}
		 */
		static Round(number, precision) {
			if (typeof precision === 'undefined') {
				precision = 0;
			}

			const factor = Math.pow(10, precision);
			const tempNumber = number * factor;
			const roundedTempNumber = Math.round(tempNumber);
			return roundedTempNumber / factor;
		}

		/**
		 * @param {number} number
		 * @param {number} decimals
		 * @param {string} decimalPoint
		 * @param {string} thousandsSeparator
		 * @param {object} opts
		 * @returns {string}
		 */
		static NumberFormat(number, decimals, decimalPoint, thousandsSeparator, opts) {
			let fixed = false;
			if (opts) {
				if (opts.fixed === true) {
					fixed = true;
				}
			}

			if (typeof number === 'string') {
				number = parseFloat(number);
			}
			if (typeof number !== 'number') {
				throw new Error('The number in Locr.Math.NumberFormat(number, decimals, decimalPoint, thousandsSeparator) must be numeric.');
			}
			if (isNaN(number)) {
				throw new Error('The number in Locr.Math.NumberFormat(number, decimals, decimalPoint, thousandsSeparator) must be numeric.');
			}
			if (number === Infinity) {
				throw new Error('The number in Locr.Math.NumberFormat(number, decimals, decimalPoint, thousandsSeparator) is infinity.');
			}

			if (decimals === undefined || decimals === null) {
				decimals = 0;
			}
			if (typeof decimals === 'string') {
				decimals = parseInt(decimals);
			}
			if (typeof decimals !== 'number') {
				throw new Error('The decimals in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) must be numeric.');
			}
			if (isNaN(decimals)) {
				throw new Error('The decimals in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) must be numeric.');
			}
			if (decimals === Infinity) {
				throw new Error('The decimals in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) is infinity.');
			}

			if (decimalPoint === undefined || decimalPoint === null) {
				decimalPoint = '.';
			}
			if (typeof decimalPoint !== 'string') {
				throw new Error('The decimalPoint in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) must be a string.');
			}
			if (thousandsSeparator === undefined || thousandsSeparator === null) {
				thousandsSeparator = ',';
			}
			if (typeof thousandsSeparator !== 'string') {
				throw new Error('The decimalPoint in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) must be a string.');
			}

			if (decimalPoint === thousandsSeparator) {
				throw new Error('The decimalPoint and the thousandsSeparator in Locr.Math.decimalsFormat(number, decimals, decimalPoint, thousandsSeparator) cannot be equal.');
			}

			let result = Locr.Math.Round(number, decimals).toString();
			const numberMatch = result.match(/(-)?([0-9]+)(\.([0-9]+))?/);
			if (numberMatch) {
				let beforeComma = parseInt(numberMatch[2]);
				let afterComma = numberMatch[4];
				if (fixed) {
					if (!afterComma) {
						afterComma = '';
					}
					while(afterComma.length < decimals) {
						afterComma += '0';
					}
				}
				let rest = beforeComma % 1000;
				const beforeCommaParts = [];
				do {
					rest = (beforeComma % 1000).toString();
					beforeComma = Math.floor(beforeComma / 1000);
					if (beforeComma > 0) {
						while(rest.length < 3) {
							rest = '0' + rest;
						}
					}
					beforeCommaParts.push(rest);
				} while(beforeComma > 0);
				beforeCommaParts.reverse();
				const sign = numberMatch[1] || '';
				result = sign + beforeCommaParts.join(thousandsSeparator);
				if (afterComma) {
					result += decimalPoint + afterComma;
				}
			}

			return result;
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['length'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Rectangle = class Rectangle {
		constructor(width, height) {
			this._width = null;
			this._height = null;
			this._dpi = null;

			if (typeof width === 'undefined') {
				throw new Error('The parameter width is undefined. But it must be of type Locr.Length or string.');
			}
			if (typeof height === 'undefined') {
				throw new Error('The parameter height is undefined. But it must be of type Locr.Length or string.');
			}

			if (width instanceof Locr.Length) {
				this._width = width;
			} else if (typeof width === 'string') {
				this._width = new Locr.Length(width);
			} else {
				throw new Error('The parameter width is not of type Locr.Length or string.');
			}
			if (height instanceof Locr.Length) {
				this._height = height;
			} else if (typeof height === 'string') {
				this._height = new Locr.Length(height);
			} else {
				throw new Error('The parameter height is not of type Locr.Length or string.');
			}

			this._dpi = this._width.DPI;
		}

		/**
		 * @returns {number}
		 */
		get DPI() {
			return this._dpi;
		}

		/**
		 * @param {number} value
		 */
		set DPI(value) {
			this._width.DPI = value;
			this._height.DPI = value;
			this._dpi = this._width.DPI;
		}

		/**
		 * @returns {number}
		 */
		get Width() {
			return this._width.Value;
		}

		/**
		 * @returns {number}
		 */
		get Height() {
			return this._height.Value;
		}

		/**
		 * @returns {number}
		 */
		get WidthPx() {
			return this._width.ToPixel().Value;
		}

		/**
		 * @returns {number}
		 */
		get HeightPx() {
			return this._height.ToPixel().Value;
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../../locr', '../geo'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.Boundings = class Boundings {
		constructor(latitudeMin, latitudeMax, longitudeMin, longitudeMax) {
			this._latitudeMin = -90.0;
			this._latitudeMax = 90.0;
			this._longitudeMin = -180.0;
			this._longitudeMax = 180.0;

			if (typeof latitudeMin !== 'undefined' && typeof latitudeMax !== 'undefined' && typeof longitudeMin !== 'undefined' && typeof longitudeMax !== 'undefined') {
				this.LatitudeMin = latitudeMin;
				this.LatitudeMax = latitudeMax;
				this.LongitudeMin = longitudeMin;
				this.LongitudeMax = longitudeMax;
			}
		}

		/**
		 * @returns {number}
		 */
		get LatitudeMin() {
			return this._latitudeMin;
		}

		/**
		 * @param {number|string} value
		 */
		set LatitudeMin(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for LatitudeMin is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for LatitudeMin is not numeric.');
			}

			if (value < -90.0 || value > 90.0) {
				throw new Error('The value for LatitudeMin must be between -90.0 and 90.0.');
			}

			this._latitudeMin = value;
		}

		/**
		 * @returns {number}
		 */
		get LatitudeMax() {
			return this._latitudeMax;
		}

		/**
		 * @param {number|string} value
		 */
		set LatitudeMax(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for LatitudeMax is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for LatitudeMax is not numeric.');
			}

			if (value < -90.0 || value > 90.0) {
				throw new Error('The value for LatitudeMax must be between -90.0 and 90.0.');
			}

			this._latitudeMax = value;
		}

		/**
		 * @returns {number}
		 */
		get LongitudeMin() {
			return this._longitudeMin;
		}

		/**
		 * @param {number|string} value
		 */
		set LongitudeMin(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for LongitudeMin is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for LongitudeMin is not numeric.');
			}

			if (value < -540.0 || value > 540.0) {
				throw new Error('The value for LongitudeMin must be between -90.0 and 90.0.');
			}

			this._longitudeMin = value;
		}

		/**
		 * @returns {number}
		 */
		get LongitudeMax() {
			return this._longitudeMax;
		}

		/**
		 * @param {number|string} value
		 */
		set LongitudeMax(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for LongitudeMax is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for LongitudeMax is not numeric.');
			}

			if (value < -540.0 || value > 540.0) {
				throw new Error('The value for LongitudeMax must be between -90.0 and 90.0.');
			}

			this._longitudeMax = value;
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		get NorthEast() {
			return new Locr.Geo.Position(this._latitudeMax, this._longitudeMax);
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		get SouthEast() {
			return new Locr.Geo.Position(this._latitudeMin, this._longitudeMax);
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		get SouthWest() {
			return new Locr.Geo.Position(this._latitudeMin, this._longitudeMin);
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		get NorthWest() {
			return new Locr.Geo.Position(this._latitudeMax, this._longitudeMin);
		}

		/**
		 * @param {number|string} latitude
		 * @param {number|string} longitude
		 * @returns {Locr.Geo.Boundings}
		 */
		ExpandByLatitudeLongitude(latitude, longitude) {
			let latitudeType = typeof latitude;
			let longitudeType = typeof longitude;
			if (latitudeType === 'undefined' || longitudeType === 'undefined') {
				return;
			}

			if (latitudeType === 'string') {
				latitude = parseFloat(latitude);
				if (isNaN(latitude)) {
					throw new Error('The latitude not numeric.');
				}
				latitudeType = typeof latitude;
			}
			if (longitudeType === 'string') {
				longitude = parseFloat(longitude);
				if (isNaN(longitude)) {
					throw new Error('The longitude not numeric.');
				}
				longitudeType = typeof longitude;
			}
			if (latitudeType !== 'number') {
				throw new Error('The latitude is not numeric.');
			}
			if (longitudeType !== 'number') {
				throw new Error('The longitude is not numeric.');
			}

			if (latitude < -90.0 || latitude > 90.0) {
				throw new Error('The latitude must be between -90.0 and 90.0.');
			}
			if (longitude < -180.0 || longitude > 180.0) {
				throw new Error('The longitude must be between -90.0 and 90.0.');
			}

			return new Boundings(Math.min(this._latitudeMin, latitude), Math.max(this._latitudeMax, latitude), Math.min(this._longitudeMin, longitude), Math.max(this._longitudeMax, longitude));
		}

		/**
		 * @param {Locr.Geo.Position} position
		 * @returns {Locr.Geo.Boundings}
		 */
		ExpandByPosition(position) {
			if (!(position instanceof Locr.Geo.Position)) {
				throw new Error('The position must be an instance of Locr.Geo.Position.');
			}

			return new Boundings(Math.min(this._latitudeMin, position.Latitude), Math.max(this._latitudeMax, position.Latitude), Math.min(this._longitudeMin, position.Longitude), Math.max(this._longitudeMax, position.Longitude));
		}

		/**
		 * @param {Locr.Geo.Boundings} boundings
		 * @returns {boolean}
		 */
		Within(boundings) {
			if (boundings instanceof Locr.Geo.Boundings) {
				if (this.LatitudeMin < boundings.LatitudeMin) {
					return false;
				}
				if (this.LatitudeMax > boundings.LatitudeMax) {
					return false;
				}
				if (this.LongitudeMin < boundings.LongitudeMin) {
					return false;
				}
				if (this.LongitudeMax > boundings.LongitudeMax) {
					return false;
				}

				return true;
			} else {
				throw new Error('The position must be an instance of Locr.Geo.Boundings or Locr.Geo.Position.');
			}
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../geo'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.GPolyline = class GPolyline {
		/**
		 * @param {string} encoded
		 * @param {number} precision
		 * @return {array}
		 */
		static Decode(encoded, precision = 5) {
			const coordinates = [];

			let index = 0;
			let lat = 0.0;
			let lng = 0.0;
			let shift = 0;
			let result = 0;
			let byte = 0;
			let latitudeChange = 0.0;
			let longitudeChange = 0.0;
			const factor = Math.pow(10, precision);

			const strLength = encoded.length;
			while(index < strLength) {
				byte = 0;
				shift = 0;
				result = 0;

				do {
					byte = encoded.charCodeAt(index) - 63;
					index++;
					result |= (byte & 0x1f) << shift;
					shift += 5;
				} while(byte >= 0x20);

				latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

				shift = result = 0;

				do {
					byte = encoded.charCodeAt(index) - 63;
					index++;
					result |= (byte & 0x1f) << shift;
					shift += 5;
				} while(byte >= 0x20);

				longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

				lat += latitudeChange;
				lng += longitudeChange;

				const point = [
					lat / factor,
					lng / factor
				];
				coordinates.push(point);
			}

			return coordinates;
		}

		/**
		 * @param {array} coordinates
		 * @param {number} precision
		 * @returns {string}
		 */
		static Encode(coordinates, precision = 5) {
			if (coordinates.length === 0) {
				return '';
			}

			const factor = Math.pow(10, precision);
			let output = '';
			output += GPolyline.EncodePoint(coordinates[0][0], factor);
			output += GPolyline.EncodePoint(coordinates[0][1], factor);

			let previous = coordinates[0];
			for(let i = 1; i < coordinates.length; i++) {
				const current = coordinates[i];
				output += GPolyline.EncodePoint(current[0] - previous[0], factor);
				output += GPolyline.EncodePoint(current[1] - previous[1], factor);
				previous = current;
			}

			return output;
		}

		/**
		 * @param {number} value
		 * @param {number} factor
		 * @return {string}
		 */
		static EncodePoint(value, factor) {
			let integer = Math.round(value * factor);
			integer <<= 1;
			if (integer < 0) {
				integer = ~integer;
			}

			let output = '';
			while(integer >= 0x20) {
				const chr = ((0x20 | (integer & 0x1f)) + 63);
				output += String.fromCharCode(chr);
				integer >>= 5;
			}
			output += String.fromCharCode(integer + 63);

			return output;
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../geo'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geo.Position = class Position {
		constructor(latitude, longitude) {
			this._latitude = 0.0;
			this._longitude = 0.0;

			if (typeof latitude !== 'undefined' && typeof longitude !== 'undefined') {
				this.Latitude = latitude;
				this.Longitude = longitude;
			}
		}

		/**
		 * @return boolean
		 */
		get IsGeotagged() {
			return (this._latitude !== 0.0 || this._longitude !== 0.0);
		}

		/**
		 * @return number
		 */
		get Latitude() {
			return this._latitude;
		}

		/**
		 * @param number|string value
		 */
		set Latitude(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for Latitude is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for Latitude is not numeric.');
			}

			if (value < -90.0 || value > 90.0) {
				throw new Error('The value for Latitude must be between -90.0 and 90.0.');
			}

			this._latitude = value;
		}

		/**
		 * @return number
		 */
		get Longitude() {
			return this._longitude;
		}

		/**
		 * @param number|string value
		 */
		set Longitude(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for Longitude is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for Longitude is not numeric.');
			}

			if (value < -540.0 || value > 540.0) {
				throw new Error('The value for Longitude must be between -540.0 and 540.0.');
			}

			this._longitude = value;
		}

		/**
		 * @returns {Locr.Geo.Position}
		 */
		Clone() {
			return new Locr.Geo.Position(this._latitude, this._longitude);
		}

		/**
		 * @param {Locr.Geo.Position} comparePosition
		 * @returns {boolean}
		 */
		Equals(comparePosition) {
			if (this.Latitude !== comparePosition.Latitude) {
				return false;
			}
			if (this.Longitude !== comparePosition.Longitude) {
				return false;
			}

			return true;
		}

		/**
		 * @param {number|string|Locr.Length} length
		 * @returns {Locr.Geo.Boundings}
		 */
		GetBoundingsByLength(length) {
			if (length instanceof Locr.Length) {
				length = length.ToMeter().Value;
			} else {
				let lengthType = typeof length;
				if (lengthType === 'string') {
					try {
						length = new Locr.Length(length).ToMeter().Value;
						lengthType = 'number';
					} catch (exc) {
						const parsedLength = parseFloat(length);
						if (isNaN(parsedLength)) {
							throw new Error('length could not been parsed as number.');
						}
						length = parsedLength;
						lengthType = 'number';
					}
				}
			}

			const north = this.GetPositionByDirectionAndLength(0, length);
			const east = this.GetPositionByDirectionAndLength(90, length);
			const south = this.GetPositionByDirectionAndLength(180, length);
			const west = this.GetPositionByDirectionAndLength(270, length);

			return new Locr.Geo.Boundings(south.Latitude, north.Latitude, west.Longitude, east.Longitude);
		}

		/**
		 * @param {Locr.Geo.Position} distantPoint
		 * @returns {Locr.Length}
		 */
		GetDistance(distantPoint) {
			if (this.Equals(distantPoint)) {
				return new Locr.Length();
			}

			const lat1 = this.Latitude / 180 * Math.PI;
			const long1 = this.Longitude / 180 * Math.PI;
			const lat2 = distantPoint.Latitude / 180 * Math.PI;
			const long2 = distantPoint.Longitude / 180 * Math.PI;

			let distance = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(long2 - long1));
			distance *= Locr.Geo.EQUATORIAL_RADIUS;

			return new Locr.Length(distance, 'm');
		}

		/**
		 * @param {number} direction
		 * @param {number|string|Locr.Length} length
		 * @returns {Locr.Geo.Position}
		 */
		GetPositionByDirectionAndLength(direction, length) {
			if (length instanceof Locr.Length) {
				length = length.ToMeter().Value;
			} else {
				let lengthType = typeof length;
				if (lengthType === 'string') {
					try {
						length = new Locr.Length(length).ToMeter().Value;
						lengthType = 'number';
					} catch (exc) {
						const parsedLength = parseFloat(length);
						if (isNaN(parsedLength)) {
							throw new Error('length could not been parsed as number.');
						}
						length = parsedLength;
						lengthType = 'number';
					}
				}
			}

			const position = new Position();

			const meterPerDegreeLatitude = Locr.Geo.METER_PER_DEGREE_LATITUDE * 60;
			const meterPerDegreeLongitude = Math.abs(Math.cos(this._latitude * Math.PI / 180) * meterPerDegreeLatitude);

			if (direction >= 0 && direction <= 90) { // Quadrant 1
				const latMeter = Math.cos(Locr.Math.Deg2Rad(direction)) * length;
				const degreeLatDivider1 = (latMeter !== 0) ? meterPerDegreeLatitude / latMeter : 0;
				const longMeter = Math.sin(Locr.Math.Deg2Rad(direction)) * length;
				const degreeLongDivider = (longMeter !== 0) ? meterPerDegreeLongitude / longMeter : 0;

				const newLatitude1 = (degreeLatDivider1 !== 0) ? this._latitude + (1 / degreeLatDivider1) : this._latitude;
				const newLongitude = (degreeLongDivider !== 0) ? this._longitude + (1 / degreeLongDivider) : this._longitude;

				position.Latitude = newLatitude1;
				position.Longitude = newLongitude;
			} else if (direction >= 90 && direction <= 180) { // Quadrant 2
				const latMeter = Math.sin(Locr.Math.Deg2Rad(direction - 90)) * length;
				const degreeLatDivider = (latMeter !== 0) ? meterPerDegreeLatitude / latMeter : 0;
				const longMeter = Math.cos(Locr.Math.Deg2Rad(direction - 90)) * length;
				const degreeLongDivider = (longMeter !== 0) ? meterPerDegreeLongitude / longMeter : 0;

				const newLatitude = (degreeLatDivider !== 0) ? this._latitude - (1 / degreeLatDivider) : this._latitude;
				const newLongitude = (degreeLongDivider !== 0) ? this._longitude + (1 / degreeLongDivider) : this._longitude;

				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			} else if (direction >= 180 && direction <= 270) { // Quadrant 3
				const latMeter = Math.cos(Locr.Math.Deg2Rad(direction - 180)) * length;
				const degreeLatDivider = (latMeter !== 0) ? meterPerDegreeLatitude / latMeter : 0;
				const longMeter = Math.sin(Locr.Math.Deg2Rad(direction - 180)) * length;
				const degreeLongDivider = (longMeter !== 0) ? meterPerDegreeLongitude / longMeter : 0;

				const newLatitude = (degreeLatDivider !== 0) ? this._latitude - (1 / degreeLatDivider) : this._latitude;
				const newLongitude = (degreeLongDivider !== 0) ? this._longitude - (1 / degreeLongDivider) : this._longitude;

				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			} else if (direction >= 270 && direction <= 360) { // Quadrant 4
				const latMeter = Math.sin(Locr.Math.Deg2Rad(direction - 270)) * length;
				const degreeLatDivider = (latMeter !== 0) ? meterPerDegreeLatitude / latMeter : 0;
				const longMeter = Math.cos(Locr.Math.Deg2Rad(direction - 270)) * length;
				const degreeLongDivider = (longMeter !== 0) ? meterPerDegreeLongitude / longMeter : 0;

				const newLatitude = (degreeLatDivider !== 0) ? this._latitude + (1 / degreeLatDivider) : this._latitude;
				const newLongitude = (degreeLongDivider !== 0) ? this._longitude - (1 / degreeLongDivider) : this._longitude;

				position.Latitude = newLatitude;
				position.Longitude = newLongitude;
			}

			return position;
		}

		ToFormattedLatitude(format) {
			return this.ToFormatted(format, this._latitude, 'latitude');
		}

		ToFormattedLongitude(format) {
			return this.ToFormatted(format, this._longitude, 'longitude');
		}

		ToFormatted(format, value, latitudeOrLongitude) {
			let formatted = '';

			let directionCharacter = '';
			if (latitudeOrLongitude === 'latitude') {
				directionCharacter = (value < 0) ? 'S' : 'N';
			} else {
				directionCharacter = (value < 0) ? 'W' : 'E';
			}
			const dms = this.CalculateDMS(value);
			switch (format) {
				case Locr.Geo.COORDINATE_FORMAT_DECIMAL:
					formatted = value.toString();
					break;

				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS: // (+/-)DD° MM′ SS″
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + Math.floor(dms.S) + '″';
					break;

				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DIRECTION: // DD° MM′ SS″ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + Math.floor(dms.S) + '″ ' + directionCharacter;
					break;

				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_SECONDS_DECIMAL: // (+/-)DD° MM′ SS.SS″
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + dms.S.toFixed(2) + '″';
					break;

				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_SECONDS_DECIMAL_DIRECTION: // DD° MM′ SS.SS″ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + Math.floor(dms.M) + '′ ' + dms.S.toFixed(2) + '″ ' + directionCharacter;
					break;

				case Locr.Geo.COORDINATE_FORMAT_SIGNED_DEGREES_MINUTES_DECIMAL: // (+/-)DD° MM.MMMM′
					if (value < 0) {
						formatted = '-';
					}
					formatted += Math.floor(Math.abs(dms.D)) + '° ' + dms.M.toFixed(4) + '′';
					break;

				case Locr.Geo.COORDINATE_FORMAT_DEGREES_MINUTES_DECIMAL_DIRECTION: // DD° MM.MMMM′ direction
					formatted = Math.floor(Math.abs(dms.D)) + '° ' + dms.M.toFixed(4) + '′ ' + directionCharacter;
					break;

				default:
					throw new Error('Invalid format given to Locr.Geo.Position.ToFormatted(format, value)');
			}

			return formatted;
		}

		/**
		 * @param {double} value
		 * @return {object}
		 */
		CalculateDMS(value) {
			const absValue = Math.abs(value);
			const fullDegrees = Math.floor(absValue);

			const restMinutes = (absValue - fullDegrees) * 60;
			const fullMinutes = Math.floor(restMinutes);

			const restSeconds = (restMinutes - fullMinutes) * 60;

			return {
				D: value,
				M: restMinutes,
				S: restSeconds
			};
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../geometry'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geometry.Vector = class Vector {
		/**
         * @param {Number} x
         * @param {Number} y
         */
		constructor(x, y) {
			this._x = 0.0;
			this._y = 0.0;

			if (typeof x !== 'undefined') {
				this.X = x;
			}
			if (typeof y !== 'undefined') {
				this.Y = y;
			}
		}

		/**
         * @returns {Number}
         */
		get Direction() {
			let result = 0.0;

			if (this._x === 0 && this._y === 0) {
				result = 0.0; // north
			} else if (this._y < 0.0) { // north
				if (this._x > 0.0) { // east
					result = Math.asin(this._x / this.Length) * Locr.Geometry.RAD2DEG;
				} else if (this._x < 0.0) { // west
					result = 360.0 - Math.asin(Math.abs(this._x) / this.Length) * Locr.Geometry.RAD2DEG;
				} else { // north
					result = 0.0;
				}
			} else if (this._y > 0.0) { // south
				if (this._x > 0.0) { // east
					result = 180.0 - Math.asin(this._x / this.Length) * Locr.Geometry.RAD2DEG;
				} else if (this._x < 0.0) { // west
					result = 180.0 + Math.asin(Math.abs(this._x) / this.Length) * Locr.Geometry.RAD2DEG;
				} else {
					result = 180.0;
				}
			} else { // vector points to the east or to the west
				if (this._x > 0.0) {
					result = 90.0;
				} else if (this._x < 0.0) {
					result = 270.0;
				}
			}

			return result;
		}

		/**
         * @returns {Number}
         */
		get Length() {
			return Math.sqrt(this._x * this._x + this._y * this._y);
		}

		/**
		 * @returns {Number}
		 */
		get X() {
			return this._x;
		}

		/**
		 * @param {Number|String} value
		 */
		set X(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for X is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for X is not numeric.');
			}

			this._x = value;
		}

		/**
		 * @returns {Number}
		 */
		get Y() {
			return this._y;
		}

		/**
		 * @param {Number|String} value
		 */
		set Y(value) {
			let valueType = typeof value;
			if (valueType === 'undefined') {
				return;
			}

			if (valueType === 'string') {
				value = parseFloat(value);
				if (isNaN(value)) {
					throw new Error('The value for Y is not numeric.');
				}
				valueType = typeof value;
			}
			if (valueType !== 'number') {
				throw new Error('The value for Y is not numeric.');
			}

			this._y = value;
		}

		/**
         * @param {Vector} vector
         * @returns {Boolean}
         */
		Equals(vector) {
			if (!(vector instanceof Vector)) {
				throw new Error('The "vector" must be an instance of Locr.Geometry.Vector in Locr.Geometry.Vector.Equals(vector).');
			}

			return (this._x === vector.X && this._y === vector.Y);
		}

		/**
         * @param {Vector} otherVector
         * @returns {Vector}
         */
		Add(otherVector) {
			if (!(otherVector instanceof Vector)) {
				throw new Error('The "otherVector" must be an instance of Locr.Geometry.Vector in Locr.Geometry.Vector.Add(otherVector).');
			}

			return new Vector(this._x + otherVector.X, this._y + otherVector.Y);
		}

		/**
         * @param {Vector} otherVector
         * @returns {Vector}
         */
		Subtract(otherVector) {
			if (!(otherVector instanceof Vector)) {
				throw new Error('The "otherVector" must be an instance of Locr.Geometry.Vector in Locr.Geometry.Vector.Subtract(otherVector).');
			}

			return new Vector(this._x - otherVector.X, this._y - otherVector.Y);
		}

		/**
         * @param {Number} factor
         * @returns {Vector}
         */
		MultiplyBy(factor) {
			let factorType = typeof factor;
			if (factorType === 'undefined') {
				throw new Error('The value for factor is undefined.');
			}

			if (factorType === 'string') {
				factor = parseFloat(factor);
				if (isNaN(factor)) {
					throw new Error('The value for factor is not numeric.');
				}
				factorType = typeof value;
			}
			if (factorType !== 'number') {
				throw new Error('The value for factor is not numeric.');
			}

			return new Vector(this._x * factor, this._y * factor);
		}

		/**
         * @param {Number} factor
         * @returns {Vector}
         */
		DivideBy(factor) {
			let factorType = typeof factor;
			if (factorType === 'undefined') {
				throw new Error('The value for factor is undefined.');
			}

			if (factorType === 'string') {
				factor = parseFloat(factor);
				if (isNaN(factor)) {
					throw new Error('The value for factor is not numeric.');
				}
				factorType = typeof value;
			}
			if (factorType !== 'number') {
				throw new Error('The value for factor is not numeric.');
			}

			return new Vector(this._x / factor, this._y / factor);
		}

		/**
         * @param {Number} degrees
         * @returns {Vector}
         */
		Rotate(degrees) {
			let degreesType = typeof degrees;
			if (degreesType === 'undefined') {
				return;
			}

			if (degreesType === 'string') {
				degrees = parseFloat(degrees);
				if (isNaN(degrees)) {
					throw new Error('The value for degrees is not numeric.');
				}
				degreesType = typeof value;
			}
			if (degreesType !== 'number') {
				throw new Error('The value for degrees is not numeric.');
			}

			const degreesInRadians = degrees * Locr.Geometry.DEG2RAD;

			const newX = this._x * Math.cos(degreesInRadians) - this._y * Math.sin(degreesInRadians);
			const newY = this._x * Math.sin(degreesInRadians) + this._y * Math.cos(degreesInRadians);

			return new Vector(newX, newY);
		}

		/**
         * @param {Number} length
         * @returns {Vector}
         */
		SetLength(length) {
			let lengthType = typeof length;
			if (lengthType === 'undefined') {
				return;
			}

			if (lengthType === 'string') {
				length = parseFloat(length);
				if (isNaN(length)) {
					throw new Error('The value for length is not numeric.');
				}
				lengthType = typeof value;
			}
			if (lengthType !== 'number') {
				throw new Error('The value for length is not numeric.');
			}

			return this.MultiplyBy(length / this.Length);
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();

'use strict';

(function() {
	let Locr = {};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		const requires = ['../../locr', '../geometry', 'vector'];
		for(const requireItem of requires) {
			const required = require('./' + requireItem);
			for(const item in required) {
				Locr[item] = required[item];
			}
		}
	} else if (typeof window.Locr !== 'undefined') {
		Locr = window.Locr;
	}

	Locr.Geometry.VectorLD = class VectorLD {
		constructor(location, direction) {
			if (!(location instanceof Locr.Geometry.Vector)) {
				throw new Error('The "location" must be an instance of Locr.Geometry.Vector in new Locr.Geometry.VectorLD(direction, location).');
			}
			if (!(direction instanceof Locr.Geometry.Vector)) {
				throw new Error('The "direction" must be an instance of Locr.Geometry.Vector in new Locr.Geometry.VectorLD(direction, location).');
			}

			this._location = location;
			this._direction = direction;
		}

		/**
		 * @returns {Vector}
		 */
		get Direction() {
			return this._direction;
		}

		/**
		 * @returns {Vector}
		 */
		get Location() {
			return this._location;
		}

		/**
         * @param {VectorLD} otherVector
         * @returns {Vector}
         * @throws {String}
         */
		CalculateIntersection(otherVector) {
			if (!(otherVector instanceof VectorLD)) {
				throw new Error('The "otherVector" must be an instance of Locr.Geometry.VectorLD in Locr.Geometry.VectorLD.CalculateIntersection(otherVector).');
			}
			if (this._direction.Equals(otherVector.Direction)) {
				throw new Error('An intersection cannot been calculated for the 2 vectors, because they have equal direction vectors!');
			}

			const rR = otherVector.Direction.X / this._direction.X;
			const rL = (otherVector.Location.X - this._location.X) / this._direction.X;

			const sL = this._location.Y + this._direction.Y * rL - otherVector.Location.Y;
			const sR = otherVector.Direction.Y - this._direction.Y * rR;
			const s = sL / sR;

			const r = rL + rR * s;

			return new Locr.Geometry.Vector(this._location.X + r * this._direction.X, this._location.Y + r * this._direction.Y);
		}
	};

	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = Locr;
	} else {
		window.Locr = Locr;
	}
})();
